import http from "./http"

export async function getExcelDataByUsersCategory({ category, params, }) {
  return http.get(`admin/users/${category}/export/`, {
    responseType: "blob",
    params,
  })
}

export async function getExcelDataByGamesetId({ gamesetId, category, params, }) {
  return http.get(`admin/gamesets/${gamesetId}/${category}/export/`, {
    responseType: "blob",
    params,
  })
}

export async function getExcelDataBySurveyId({ surveyId, category, params, }) {
  return http.get(`admin/surveys/${surveyId}/${category}/export/`, {
    responseType: "blob",
    params,
  })
}

export async function getFaceStudyByGamesetId({ gamesetId, }) {
  return http.get(`admin/gameset/${gamesetId}/facestudy/export/`, {
    responseType: "blob",
  })
}

export async function getPDFByGamesetId({ gamesetId, }) {
  return http.get(`admin/gamesets/${gamesetId}/result/data/export/`, {
    responseType: "blob",
  })
}

export async function getDetectionStudyByGamesetId({ gamesetId, }) {
  return http.get(`admin/gameset/${gamesetId}/detectionstudy/export/`, {
    responseType: "blob",
  })
}

export async function getRawDataByGamesetId({ gamedataId, params, }) {
  return http.get(`admin/rawdata/${gamedataId}/export/`, {
    responseType: "blob",
    params,
  })
}

export async function getAllRawData({ gamesetId, params, }) {
  return http.get(`admin/allrawdata/${gamesetId}/export/`, {
    responseType: "blob",
    params,
  })
}

export async function getPdfFile(payload) {
  return http.post("games/company/sets/result/file/", payload)
}

export async function setTeamScore(payload, gamesetId) {
  return http.patch(`games/company/sets/${gamesetId}/score/team/`, payload)
}

export async function getTeamScore(gamesetId) {
  return http.get(`games/company/sets/${gamesetId}/score/team/`, {
    responseType: "application/json",
  })
}

export async function getPdfFileBatch(payload) {
  return http.post("games/company/sets/result/file/batch/", payload)
}
